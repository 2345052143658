<template>
  <ClFormRow
    :label="label"
    tooltipPosition="label"
    sm-label="10"
    xl-label="10"
    md-content="12"
    xl-content="12"
    :toolTipText="tooltip"
  >
    <template #content>
      <div class="adjustment-inputs zq--metadata">
        <template v-for="(field, index) in actionTypeAdjustmentFactors">
          <CRow v-bind:key="index" form>
            <CCol sm="4" md="4" lg="5" xl="3">
              <multiselect
                @search-change="onInput"
                :options="options"
                :value="field.actionType"
                @input="(val) => {
                  field.actionTypeId = val.id;
                  field.actionType = {
                    name: val.name,
                    id: val.id
                  }
                }"
                :selectLabel="selectLabel"
                :multiple="false"
                placeholder="Type here..."
                :preserve-search="true"
                track-by="id"
                label="name"
                id="adjustment-inputs--multi"
                :options-limit="25"
                :hide-selected="true"
                class="custom-multi-select zq--multiselect zq--select--no-press-btn"
              >
                <template #caret>
                  <div></div>
                </template>
              </multiselect>
            </CCol>
            <CCol sm="4" md="4" lg="5" xl="3">
              <CInput
                type="number"
                name="adjustmentFactor"
                v-model="field.adjustmentFactor"
                :placeholder="placeholders.adjustmentFactor"
                :disabled="disabled"
                min="1.0"
                step="0.1"
              />
            </CCol>
            <CCol sm="4" md="3" lg="2" class="zq--metadata--actions" v-show="!disabled">
              <CButton
                type="button"
                size="sm"
                class="zq--small-button"
                @click="deleteField(index)"
              >
                <CIcon name="cil-minus"/>
              </CButton>
              <CButton
                :disabled="!field.actionType || !field.adjustmentFactor"
                type="button"
                size="sm"
                class="zq--small-button ml-2"
                @click="addField(index)"
                v-if="actionTypeAdjustmentFactors.length - 1 === index"
              >
                <CIcon name="cil-plus"/>
              </CButton>
            </CCol>
          </CRow>
        </template>
      </div>
    </template>
  </ClFormRow>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {formConfig} from '@/config';
import * as _ from "lodash";
import {cloneDeep} from "lodash";

export default {
  name: 'ZqActionTypeAdjustmentFactorsField',
  props: {
    value: Array,
    fields: Array,
    disabled: Boolean,
    addActionTypeAdjustmentFactors: Function,
    label: String,
    tooltip: String,
  },
  data() {
    return {
      selectLabel: formConfig.selectLabel,
      actionType: null,
      options: [],
      originOptions: [],
      actionTypeAdjustmentFactors: [{
        actionTypeId: '',
        actionType: {},
        adjustmentFactor: null,
      }],
      placeholders: {adjustmentFactor: 'Adjustment Factor', actionTypeId: 'Action Type'},
    };
  },
  computed: {
    ...mapGetters('actionTypes', {
      actionTypesSuccess: 'success',
      actionTypesMessage: 'message',
      actionTypesLoading: 'loading',
      actionTypesData: 'actionTypes',
      optionsTypes: 'actionTypes',
    }),
    ...mapGetters('theme', ['theme'])
  },
  watch: {
    optionsTypes(val) {
      if (val) {
        this.options = val.reduce((acc, item) => {
          return [
            ...acc,
            {
              name: item.name,
              id: item.id
            }
          ]
        }, []);
      }
    },
    actionTypeAdjustmentFactors: {
      deep: true,
      handler() {
        // this.handlerOptions();
        if (this.actionTypeAdjustmentFactors && this.actionTypeAdjustmentFactors.length) {
          for (let i in this.actionTypeAdjustmentFactors) {
            if (this.actionTypeAdjustmentFactors[i].actionTypeId && !this.actionTypeAdjustmentFactors[i].adjustmentFactor || this.actionTypeAdjustmentFactors[i].actionTypeId && this.actionTypeAdjustmentFactors[i].adjustmentFactor === "" ) {
              this.actionTypeAdjustmentFactors[i].adjustmentFactor = 1
            }
          }
        }

        if (this.actionTypeAdjustmentFactors && this.actionTypeAdjustmentFactors.length) {
          this.$emit('input', this.validateAdjustmentFactorsData(this.actionTypeAdjustmentFactors));
        }
      }
    }
  },
  async created() {
    await this.initialize();
    this.originOptions = _.cloneDeep(this.optionsTypes);
    if (this.value && this.value.length) {
      this.actionTypeAdjustmentFactors = [];

      const ids = this.value.map(v => v.actionTypeId);
      const actionTypes = await this.handleGetActionTypesByQuery({
        queryRequest: {
          must: [
            {
              queryField: 'id',
              queryValues: ids
            }
          ]
        }
      });

      actionTypes.forEach(item => {
        let idx = this.value.findIndex(v => v.actionTypeId === item.id);
        const adjustmentFactor = this.value[idx].adjustmentFactor;
        let name = item.name;
        this.actionTypeAdjustmentFactors.push(
          {
            actionTypeId: item.id,
            actionType: {
              id: item.id,
              name: name,
            },
            adjustmentFactor: adjustmentFactor
          }
        );
      })
    } else {
      this.actionTypeAdjustmentFactors = [{
        actionTypeId: '',
        actionType: {},
        adjustmentFactor: null,
      }];
      this.addingPlaceholder();
    }
  },
  methods: {
    ...mapActions('actionTypes', ['handleGetActionTypes', 'handleGetActionTypesByQuery']),
    async initialize() {
      await this.handleGetActionTypes([], null, null);
    },
    async onInput(val) {
      if (val.length > 1) {
        const actionTypes = await this.handleGetActionTypesByQuery({
          queryRequest: {
            multiFields: [
              {
                queryFields: ['name'],
                queryValue: val
              }
            ]
          }
        });
        this.options = cloneDeep(actionTypes);

        // actionTypes.forEach((actionType) => {
        //   this.options.push({
        //     name: actionType.key,
        //     id: actionType.id
        //   })
        // });
      } else {
        this.options = [];
      }
    },
    /**
     * Validation empty key/value in actionTypeAdjustmentFactors
     * */
    validateAdjustmentFactorsData(val) {
      if (!val[0].adjustmentFactor) {
        return null;
      } else {
        return val.reduce((acc, item) => {
          return [
            ...acc,
            {
              actionTypeId: item.actionTypeId,
              adjustmentFactor: Number(item.adjustmentFactor)
            }
          ]
        }, []);
      }

    },
    addField: function (index) {
      this.actionTypeAdjustmentFactors.splice(index + 1, 0, {
        actionTypeId: '',
        actionType: {},
        adjustmentFactor: null,
      });
      this.addingPlaceholder();
    },
    deleteField: function (index) {
      if (this.actionTypeAdjustmentFactors.length === 1) {
        this.actionTypeAdjustmentFactors = [{
          actionTypeId: '',
          actionType: {},
          adjustmentFactor: null,
        }]
        this.addingPlaceholder();
      }
      if (this.actionTypeAdjustmentFactors.length > 1) {
        this.actionTypeAdjustmentFactors.splice(index, 1);
      }
    },
    addingPlaceholder: function () {
      setTimeout(() => {
        const multiselectSingleAll = document.querySelectorAll(".multiselect__single")
        let lastItem = multiselectSingleAll[multiselectSingleAll.length - 1]
        if (lastItem.innerHTML === "") {
          lastItem.append("Type here...");
          lastItem.style.color = "#b4b6b9"
        }
      })
    },
    // handlerOptions: function () {
    //   this.options = []
    //   this.options =  _.cloneDeep(this.originOptions);
    //     if (this.value) {
    //       this.originOptions.forEach(item => {
    //         let id = this.value.find(val => val.actionTypeId === item.id)?.actionTypeId
    //         if (id) {
    //           this.options.reduceRight((_, n, i, a) => n.id === id && a.splice(i, 1), null);
    //         }
    //       });
    //     } else {
    //       this.addingPlaceholder()
    //     }
    // },
  },
};
</script>

<style lang="scss">
@import '~@coreui/coreui/scss/coreui';
.zq--metadata--actions {
  min-width: fit-content;
  @include media-breakpoint-down(lg) {
    text-align: right;
  }
}
.adjustment-inputs {
  .multiselect {
    padding: 0;
  }
  .zq--multiselect-search-icon {
    top: -4px;
    right: 4px;
  }
  .multiselect__tags {
    min-height: 35px;
    padding: 0 2.5em 0 0;
  }
  .multiselect__placeholder {
    display: inline-block;
    margin-bottom: 5px;
    padding-top: 8px;
    font-style: italic;
  }
  .multiselect__input, .multiselect__single {
    padding-top: 10px;
    padding-left: 0;
    line-height: 18px;
    color: #768192;
    overflow-x: hidden;
  }
}
</style>